import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { getDay, startOfDay } from "date-fns";
import { map, orderBy, pick, without } from "lodash";

import {
  Content,
  DatabaseID,
  Entity,
  EntityType,
  hasDates,
  ID,
  isTemplate,
  Schedule,
  JsonArray,
  Period,
  PropertyMutation,
  Ref,
  toTitleOrName,
  Update,
  Template,
  PropertyValueRef,
  Json,
  HasBody,
} from "@api";

import {
  useCreateFromObject,
  useEntitySource,
  useLazyEntity,
  useLocalChanges,
  useQueueUpdates,
} from "@state/generic";
import { useLazyProperties } from "@state/databases";
import { ScheduleStoreAtom, useScheduleEntityType } from "@state/schedule";
import {
  toDayOfPeriod,
  toDayOfWeek,
  toNextDate,
  toSentence,
} from "@state/schedule/utils";
import { useEntityLabels } from "@state/settings";

import { append, ensureMany, OneOrMany } from "@utils/array";
import { formatDay } from "@utils/date";
import { composel, Fn } from "@utils/fn";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import { asMutation, asTempUpdate, asUpdate } from "@utils/property-mutations";
import { isEmptyRef, toPropertyValueRef, toRef } from "@utils/property-refs";
import { toChildLocation } from "@utils/scope";
import { plural, titleCase } from "@utils/string";
import { isLocalID, maybeTypeFromId } from "@utils/id";
import { withHardHandle } from "@utils/event";
import { isEmpty } from "@utils/rich-text";
import { useGoTo } from "@utils/navigation";
import { format, keepTime, parseTime, toISOTime } from "@utils/time";
import { fromCalDate, toCalDate, useISODate } from "@utils/date-fp";
import { equalsAny } from "@utils/logic";
import { omitEmpty as omitEmptyObj } from "@utils/object";

import { usePageId } from "@ui/app-page";
import { Button, Props as ButtonProps } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { Container } from "@ui/container";
import { DateInputPicker } from "@ui/date-picker";
import { Dropdown, Props as DropdownProps, useOpenState } from "@ui/dropdown";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight, Clock } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { Menu } from "@ui/menu";
import { MenuItem } from "@ui/menu-item";
import { RadioMenuGroup, RadioMenuItem } from "@ui/menu-item/radio";
import { Text } from "@ui/text";
import { RelationLabel } from "@ui/relation-label";
import { TemplateCreateDialog } from "@ui/template-create-dialog";
import { TemplateSelect } from "@ui/select";
import { EditableText } from "@ui/editable-text";
import { Divider } from "@ui/divider";
import { TimezoneSelect } from "@ui/select/timezone";
import { TimePicker } from "@ui/time-picker";
import { GoToButton } from "@ui/go-to-button";
import { OnHover } from "@ui/on-hover";

import styles from "./editor.module.css";

const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

interface EditProps {
  schedule: Schedule;
  instanceId?: ID;
  templateId?: ID;
  defaults?: Partial<Schedule>;
  source: DatabaseID;
  onChanged?: Fn<Maybe<Ref>, void>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
  showTemplate?: boolean;
  showNext?: boolean;
  pageId?: string;
}

export type Props = Partial<DropdownProps> &
  Omit<EditProps, "schedule"> & {
    schedule: Maybe<Ref>;
    parentId: ID;
    children?: ReactNode;
  };

export const ScheduleConfiguration = ({
  schedule,
  instanceId,
  showTemplate = true,
  showNext = true,
  templateId,
  overrides,
  onChanged,
  onSaved,
  onCancel,
  source,
  pageId,
}: Omit<EditProps, "defaults"> & { overrides?: PropertyValueRef[] }) => {
  const goTo = useGoTo();

  const [saving, setSaving] = useState(false);
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const instance = useLazyEntity(instanceId);
  const isCreating = isLocalID(schedule?.id);
  const childType = useScheduleEntityType(schedule);
  const toLabel = useEntityLabels(schedule?.source.scope, {
    plural: false,
    case: "lower",
  });
  const queue = useQueueUpdates(pageId);

  const scheduleSource = useEntitySource("schedule", source);
  const { changes, save, rollback } = useLocalChanges(
    schedule.id || "",
    ScheduleStoreAtom
  );
  const reccomended = useMemo(
    () =>
      isEmpty(safeAs<HasBody>(instance)?.body)
        ? "use-overrides"
        : "create-template",
    [instance]
  );
  const [templateMode, setTemplateMode] = useState<
    "create-template" | "use-template" | "use-overrides"
  >(reccomended || "use-overrides");

  const handleChange = (changes: OneOrMany<PropertyMutation<Schedule>>) =>
    schedule && queue(asTempUpdate(schedule, changes) as Update<Entity>);

  const handleDiscard = useCallback(() => {
    rollback();
    onCancel?.();
  }, [rollback]);

  const handleSave = useCallback(
    (template?: Ref) => {
      // If creating a template, then show create template dialog before saving
      if (templateMode === "create-template" && !template) {
        setCreatingTemplate(true);
        setSaving(true);
        return;
      }

      setSaving(true);

      // Set the schedule.useTemplate (template) to the created template
      if (template) {
        save([
          asUpdate(schedule, [
            asMutation({ field: "useTemplate", type: "relation" }, template),
            asMutation({ field: "status", type: "status" }, { id: "ACT" }),
          ]),
        ]);
      } else if (templateMode === "use-overrides") {
        // Has everything needed to create a new schedule, so set live
        save([
          asUpdate(schedule, [
            asMutation({ field: "status", type: "status" }, { id: "ACT" }),
            asMutation(
              { field: "overrides", type: "json" },
              safeAs<Json>(overrides)
            ),
          ]),
        ]);
      } else {
        save();
      }

      onChanged?.(toRef(schedule));

      if (isCreating && template) {
        onSaved?.(template);
      }

      setSaving(false);
    },
    [save, templateMode, onChanged]
  );

  useEffect(() => {
    // When creating a new schedule, set template mode depending of whether a template or instance was passed in
    setTemplateMode(templateId ? "use-template" : reccomended);
  }, [templateId]);

  if (!schedule) {
    return <>Loading...</>;
  }

  return (
    <Container stack="vertical" gap={10} padding="none">
      {creatingTemplate && instanceId && (
        <TemplateCreateDialog
          target={{ id: instanceId }}
          onCancel={() => {
            setCreatingTemplate(false);
            setSaving(false);
          }}
          onComplete={handleSave}
        />
      )}

      <ScheduleRepeatOptions mutate={handleChange} schedule={schedule} />

      {showTemplate && (
        <Field label={`${toLabel(childType, { case: "title" })} details`}>
          {schedule.useTemplate && childType && (
            <TemplateSelect
              className={styles.control}
              value={schedule.useTemplate}
              type={childType}
              scope={scheduleSource.scope}
              allowed={[childType]}
              allowNew={true}
              onChange={(t) =>
                t &&
                handleChange(
                  asMutation(
                    { field: "useTemplate", type: "relation" },
                    toRef(t)
                  )
                )
              }
            >
              <MenuItem
                iconRight={
                  <Button
                    onClick={withHardHandle(() =>
                      when(schedule.useTemplate, goTo)
                    )}
                    size="tiny"
                    icon={<ArrowUpRight />}
                  />
                }
              >
                <RelationLabel relation={schedule.useTemplate} />
              </MenuItem>
            </TemplateSelect>
          )}

          {!schedule.useTemplate && isCreating && (
            <Menu>
              <RadioMenuGroup
                value={templateMode}
                onChanged={(m) =>
                  setTemplateMode(m as "create-template" | "use-overrides")
                }
              >
                <RadioMenuItem value="use-overrides">
                  <SpaceBetween>
                    <Text>Use current values</Text>
                    {reccomended === "use-overrides" && (
                      <Text subtle>Recommended</Text>
                    )}
                  </SpaceBetween>
                </RadioMenuItem>

                <RadioMenuItem value="create-template">
                  <SpaceBetween>
                    <Text>Create new {toLabel(childType)} template</Text>
                    {reccomended === "create-template" && (
                      <Text subtle>Recommended</Text>
                    )}
                  </SpaceBetween>
                </RadioMenuItem>
              </RadioMenuGroup>
            </Menu>
          )}

          {!schedule.useTemplate && !isCreating && (
            <RadioMenuGroup value="use-overrides">
              <RadioMenuItem
                text="Use values from schedule"
                value="use-overrides"
                selected={true}
              />
            </RadioMenuGroup>
          )}
        </Field>
      )}

      <SpaceBetween>
        {showNext && (
          <Text subtle>
            Next scheduled for{" "}
            {when(
              fromCalDate(schedule.next || toNextDate(schedule)),
              formatDay
            ) || "unkown."}
          </Text>
        )}
        {!!changes?.length ? (
          <HStack>
            <Button subtle onClick={handleDiscard}>
              Discard
            </Button>
            <Button
              variant="primary-alt"
              onClick={() => handleSave()}
              loading={saving}
            >
              {isCreating ? "Create schedule" : "Save changes"}
            </Button>
          </HStack>
        ) : onCancel ? (
          <Button variant="secondary" onClick={() => onCancel?.()}>
            Close
          </Button>
        ) : undefined}
      </SpaceBetween>
    </Container>
  );
};

export const ScheduleEditor = ({
  schedule: _schedule,
  parentId,
  source,
  onChanged: onChange,
  onSaved,
  defaults: _defaults,
  children,
  open: _open,
  setOpen: _setOpen,
  ...props
}: Props) => {
  const pageId = usePageId();
  const [open, setOpen] = useOpenState(_open, _setOpen);
  const parent = useLazyEntity(parentId);
  const parentProps = useLazyProperties(parent?.source);
  const isInstance = !when(parent, isTemplate);
  const isCreating = useMemo(() => !_schedule?.id, [_schedule?.id]);
  const [editingId, setEditingId] = useState<Maybe<ID>>(_schedule?.id);
  const schedule = useLazyEntity<"schedule">(editingId);
  const create = useCreateFromObject("schedule", source?.scope, pageId, true);
  const templateId = !isInstance ? parent?.id : undefined;
  const instanceId = isInstance ? parent?.id : undefined;
  const queue = useQueueUpdates(pageId);

  // If the entity is template then create the schedule within in (since using this template will create a new shcedule)
  const scheduleSource = useMemo(
    (): DatabaseID<"schedule"> => ({
      type: "schedule",
      scope: templateId
        ? toChildLocation(source.scope, templateId)
        : source.scope,
    }),
    [source]
  );

  const handleChange = (changes: OneOrMany<PropertyMutation<Schedule>>) =>
    schedule && queue(asTempUpdate(schedule, changes) as Update<Entity>);

  const instanceOverrides = useMemo(() => {
    if (!isInstance) {
      return undefined;
    }

    if (!parent) {
      return;
    }

    return maybeMap(parentProps, (prop) => {
      // If using rich text fields you should use a template
      if (
        (prop.visibility === "hidden" &&
          !equalsAny(prop.field, ["title", "name"])) ||
        prop.readonly ||
        prop.type === "rich_text"
      ) {
        return undefined;
      }

      const value = toPropertyValueRef(parent, prop);

      if (isEmptyRef(value)) {
        return undefined;
      }

      return pick(value, ["field", "type", "value"]);
    });
  }, [parent]);

  // used to create a local instance below
  const defaults = useMemo(() => {
    const name = parent && !isTemplate(parent) ? toTitleOrName(parent) : "";
    // When creating from instance, use from date of instance
    const from =
      when(parent, (p) =>
        hasDates(p) ? p.start || p.end : safeAs<Content>(p)?.publish
      ) || toCalDate(now(), "local");

    if (parent && isInstance) {
      // Set from & last to current instance date
      return {
        name,
        from: from,
        last: from,
        daysOfPeriod: when(from, (d) => [useISODate(d, getDay)]),
        useTemplate: undefined,
        instances: when(instanceId, composel(toRef, ensureMany)),
        ..._defaults,
      };
    }

    // Parent that is trying to be repeated is a template
    if (parent && !isInstance) {
      return {
        name,
        from: undefined,
        last: undefined,

        status: { id: "ACT" },
        template: "nested" as Template,
        useTemplate: when(templateId, toRef),
        instances: [],
        ..._defaults,
      };
    }

    return {
      name,
      ..._defaults,
    };
  }, [parent, _defaults]);

  const handleSaved = useCallback(
    (ref: Ref) => {
      if (isCreating) {
        onSaved?.(ref);
      }
      setOpen(false);
    },
    [onSaved]
  );

  const handleCancel = useCallback(() => {
    setEditingId(_schedule?.id);
    setOpen(false);
    props.onCancel?.();
  }, []);

  // Create a local schedule (unpersisted) if no ref is passed in
  useEffect(() => {
    if (!editingId && open && create) {
      const from = toCalDate(startOfDay(now()), "local");
      const [saved] = create([
        {
          from: from,
          period: Period.Week,
          daysOfPeriod: [toDayOfPeriod(from, Period.Week)],
          frequency: 1,
          precreate: 1,
          timeOfDay: undefined,

          entity:
            when(instanceId || templateId, (id) =>
              maybeTypeFromId<EntityType>(id)
            ) || "task",

          location: scheduleSource.scope,
          source: scheduleSource,
          ...omitEmptyObj(defaults),
        },
      ]);

      setEditingId(saved.id);
    }
  }, [editingId, open, create]);

  return (
    <Dropdown
      {...props}
      open={open}
      setOpen={setOpen}
      closeOnEscape={false}
      closeOnClickAway={false}
      className={{ popover: styles.dropdown }}
      trigger={
        children || (
          <Button subtle size="small">
            Schedule
          </Button>
        )
      }
    >
      {open && schedule && (
        <Container size="half" stack="vertical" gap={10}>
          <SpaceBetween align="flex-start">
            <VStack gap={0} fit="container">
              <EditableText
                text={schedule?.name || ""}
                onChange={(name) =>
                  handleChange(
                    asMutation({ field: "name", type: "text" }, name)
                  )
                }
                updateOn="change"
                placeholder="Name this repeating work..."
              />
              <Text subtle>{toSentence(schedule)}</Text>
            </VStack>
            {!isCreating && editingId && (
              <GoToButton item={{ id: editingId }} text="Open" />
            )}
          </SpaceBetween>

          <Divider direction="horizontal" />

          {open && (
            <ScheduleConfiguration
              schedule={schedule}
              templateId={templateId}
              instanceId={instanceId}
              overrides={instanceOverrides}
              source={source}
              onChanged={onChange}
              onSaved={handleSaved}
              onCancel={handleCancel}
              pageId={pageId}
            />
          )}
        </Container>
      )}
    </Dropdown>
  );
};

export const ScheduleButton = ({
  schedule: ref,
  subtle,
  ...props
}: { schedule: Maybe<Ref> } & ButtonProps) => {
  const schedule = useLazyEntity<"schedule">(ref?.id);

  return (
    <OnHover.Trigger>
      <Button subtle size="small" {...props}>
        <SpaceBetween>
          <Text subtle={subtle}>
            {schedule ? toSentence(schedule) : "Setup a repeating schedule"}
          </Text>
          <OnHover.Target opacity="partial">
            <GoToButton item={ref} />
          </OnHover.Target>
        </SpaceBetween>
      </Button>
    </OnHover.Trigger>
  );
};

export const ScheduleRepeatOptions = ({
  schedule,
  mutate,
  showStart = true,
}: {
  schedule: Schedule;
  showStart?: boolean;
  mutate: Fn<OneOrMany<PropertyMutation<Schedule>>, void>;
}) => {
  const toLabel = useEntityLabels(schedule.source.scope, {
    plural: true,
    case: "lower",
  });
  const time = useMemo(
    () => when(schedule.timeOfDay, parseTime),
    [schedule.timeOfDay]
  );

  return (
    <Container stack="vertical" gap={10} padding="none">
      {showStart && (
        <Field label="Starting" layout="horizontal">
          <FillSpace fit="container">
            <DateInputPicker
              portal={true}
              date={fromCalDate(schedule.from, "local")}
              onChanged={(d) =>
                !!d &&
                mutate([
                  asMutation(
                    { field: "from", type: "date" },
                    keepTime(toCalDate(d, "local"), schedule.from)
                  ),
                  asMutation({ field: "daysOfPeriod", type: "json" }, [
                    toDayOfPeriod(toCalDate(d, "local"), schedule.period),
                  ]),
                ])
              }
            />
          </FillSpace>
        </Field>
      )}

      <Field label="Repeat every" layout="horizontal">
        <SpaceBetween gap={6}>
          <TextInput
            value={when(schedule.frequency, String) || "0"}
            updateOn="change"
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "frequency", type: "number" },
                  parseInt(v, 10)
                )
              )
            }
            inputType="number"
          />
          <ButtonGroup>
            {map(["day", "week", "month", "year"], (period) => (
              <SplitButton
                key={period}
                size="small"
                selected={schedule.period === period}
                onClick={() =>
                  mutate([
                    asMutation({ field: "period", type: "text" }, period),
                    asMutation(
                      { field: "daysOfPeriod", type: "json" },
                      when(schedule.from, (f) => [
                        toDayOfPeriod(f, period as Period),
                      ]) as JsonArray
                    ),
                  ])
                }
              >
                {titleCase(period)}
              </SplitButton>
            ))}
          </ButtonGroup>
        </SpaceBetween>
      </Field>

      {schedule.period === "week" && (
        <Field label="On" layout="horizontal">
          <HStack fit="container">
            <ButtonGroup fit="container">
              {map(WEEKDAYS, (day) => (
                <SplitButton
                  key={day}
                  size="small"
                  selected={schedule.daysOfPeriod?.includes(day) ?? false}
                  onClick={() => {
                    mutate(
                      asMutation(
                        { field: "daysOfPeriod", type: "json" },
                        schedule?.daysOfPeriod?.includes(day)
                          ? without(schedule.daysOfPeriod, day)
                          : // Always keep daysOfPeriod ordered
                            orderBy(append(schedule.daysOfPeriod, day))
                      )
                    );
                  }}
                >
                  {titleCase(toDayOfWeek(day)?.slice(0, 3))}
                </SplitButton>
              ))}
            </ButtonGroup>
          </HStack>
        </Field>
      )}

      {/* Currently only meetings require time/zone */}
      {schedule.entity === "meeting" && (
        <Field label="Time" layout="horizontal">
          <SpaceBetween fit="container" gap={6}>
            <FillSpace>
              <TimePicker
                time={time}
                onChange={(t) =>
                  mutate(
                    asMutation(
                      { field: "timeOfDay", type: "text" },
                      when(t, toISOTime)
                    )
                  )
                }
              >
                <Button
                  className={styles.control}
                  fit="container"
                  size="small"
                  subtle
                  icon={Clock}
                >
                  {when(time, format) || "Choose time"}
                </Button>
              </TimePicker>
            </FillSpace>
            <Text subtle>in</Text>
            <FillSpace>
              <TimezoneSelect
                placeholder="Local timezone"
                value={schedule.timeZone}
                onChange={(tz) =>
                  mutate(
                    asMutation(
                      { field: "timeZone", type: "text" },
                      tz || undefined
                    )
                  )
                }
              />
            </FillSpace>
          </SpaceBetween>
        </Field>
      )}

      <Field label="Until" layout="horizontal">
        <FillSpace fit="container">
          <DateInputPicker
            date={fromCalDate(schedule.to, "local")}
            placeholder="Forever"
            onChanged={(d) =>
              !!d &&
              mutate(
                asMutation({ field: "to", type: "date" }, toCalDate(d, "local"))
              )
            }
          />
        </FillSpace>
      </Field>

      <Field
        label={`Create ${when(schedule.entity, toLabel) || "work"}`}
        layout="horizontal"
      >
        <HStack fit="container" justify="flex-start">
          <div className={styles.smallInput}>
            <TextInput
              value={when(schedule.precreate, String) || "1"}
              updateOn="change"
              onChange={(v) =>
                mutate(
                  asMutation(
                    { field: "precreate", type: "number" },
                    Math.max(parseInt(v, 10), 1)
                  )
                )
              }
              inputType="number"
            />
          </div>
          <FillSpace direction="horizontal">
            <Text subtle>
              {plural("week", schedule.precreate || 1)} in advance
            </Text>
          </FillSpace>
        </HStack>
      </Field>
    </Container>
  );
};
