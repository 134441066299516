import { FunctionComponent, ReactNode } from "react";
import {
  Entity,
  ID,
  PropertyDef,
  PropertyRef,
  PropertyValueRef,
  Ref,
  Update,
  WorkspaceConfig,
} from "@api";
import { TypeForEntity } from "@api/mappings";

import { AllStores } from "@state/generic";

import { Maybe } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";
import { Fn } from "@utils/fn";
import { OneOrMany } from "@utils/array";
import { OrStar } from "@utils/wildcards";

export interface WorkflowData<T extends Entity> {
  entity: T;
}

export type RefOrID = ID | Ref;

export const toId = (id: RefOrID): ID => (typeof id === "string" ? id : id.id);

export interface WorkflowContext<T extends Entity> {
  stores: AllStores;
  props: PropertyDef<T>[];
  session: WorkspaceConfig;
  getItem: (id: Maybe<RefOrID>) => Maybe<Entity>;
  getItems: (id: Maybe<RefOrID[]>) => Maybe<Entity>[];
  fetchItem: (id: Maybe<RefOrID>) => Promise<Maybe<Entity>>;
  fetchItems: (ids: Maybe<RefOrID[]>) => Promise<Maybe<Entity>[]>;
  transaction?: ID;
}

export type WorkflowAction<T extends Entity, P = PropertyValueRef<T>[]> = {
  id: ID;
  trigger: "ACTION";
  icon?: ComponentOrNode;
  variant?: "primary" | "secondary"; // Override
  title: string;
  description?: string;
  type: TypeForEntity<T> | TypeForEntity<T>[] | "*";
  collect?:
    | PropertyRef<T>[]
    | FunctionComponent<{
        data: WorkflowData<T>;
        onCollected: Fn<P, void>;
        onCancelled: Fn<void, void>;
        context: WorkflowContext<T>;
      }>;
  allowed: (data: WorkflowData<T>, context: WorkflowContext<T>) => boolean;
  execute: (
    data: WorkflowData<T> & { collected: P },
    context: WorkflowContext<T>
  ) => Maybe<Update<T> | Update<T>[]>;
};

// TODO: Make execute return any update type (not just the entity type of the definition)
export type WorkflowTriggerWillUpdate<E extends Entity> = {
  id: ID;
  type: OrStar<OneOrMany<TypeForEntity<E>>>;
  trigger: "WILL_UPDATE";
  allowed: (
    data: WorkflowData<E> & { update: Update<E> },
    context: WorkflowContext<E>
  ) => boolean;
  execute: (
    data: WorkflowData<E> & { update: Update<E> },
    context: WorkflowContext<E>
  ) => Maybe<OneOrMany<Update<E>>>;
};

export type WorkflowTriggerDidUpdate<E extends Entity> = {
  id: ID;
  type: TypeForEntity<E> | TypeForEntity<E>[] | "*";
  trigger: "DID_UPDATE";
  allowed: (
    data: WorkflowData<E> & { update: Update<E> },
    context: WorkflowContext<E>
  ) => boolean;
  execute: (
    data: WorkflowData<E> & { update: Update<E> },
    context: WorkflowContext<E>
  ) => Promise<Maybe<OneOrMany<Update<E>>>>;
};

export type WorkflowTrigger<E extends Entity> =
  | WorkflowTriggerWillUpdate<E>
  | WorkflowTriggerDidUpdate<E>;

export type WorkflowSuggestion<E extends Entity> = {
  id: ID;
  trigger: "SUGGEST";
  type: TypeForEntity<E> | TypeForEntity<E>[] | "*";
  allowed: (
    data: WorkflowData<E> & { update: Update<E> },
    context: WorkflowContext<E>
  ) => boolean;
  suggestion: {
    id: ID;
    text: string;
    description?: string;
    icon?: ComponentOrNode;
    options: { title: string; id: ID }[];
  };
  title?: string;
  description?: string;
  collect?:
    | PropertyRef<E>[]
    | FunctionComponent<{
        data: WorkflowData<E> & { update: Update<E> };
        onCollected: Fn<PropertyValueRef<E>[], void>;
        onCancelled: Fn<void, void>;
        context: WorkflowContext<E>;
      }>;
  execute: (
    data: WorkflowData<E> & { collected: PropertyValueRef<E>[] },
    context: WorkflowContext<E>
  ) => Maybe<Update<E> | Update<E>[]>;
};

export type WorkflowDefinition<T extends Entity = Entity> =
  | WorkflowTrigger<T>
  | WorkflowAction<T>
  | WorkflowSuggestion<T>;

export type WorkflowDefinitionConfig<T extends Entity = Entity> = {
  actions?: WorkflowAction<T, any>[];
  triggers?: WorkflowTrigger<T>[];
  suggestions?: WorkflowSuggestion<T>[];
};
