import { useCallback, useMemo, useState } from "react";
import { filter, flatMap, isEmpty, keys, map, mapKeys, some } from "lodash";

import { Form, HasDates, HasRefs, Ref } from "@api";

import {
  NestableOverrides,
  useCreateFromObject,
  useCreateFromTemplate,
  flattenFor,
  useLazyEntity,
} from "@state/generic";
import { useMe } from "@state/persons";
import {
  FormData,
  isReadonlyField,
  makeNestedOverrides,
  useFormFields,
} from "@state/form";
import { formSubmissionName, useAiUseCase } from "@state/ai";

import { useSlowMemo } from "@utils/hooks";
import { Maybe, safeAs, when } from "@utils/maybe";
import { explode } from "@utils/confetti";
import { equalsAny } from "@utils/logic";
import { ensureMany, justOne, maybeLookup } from "@utils/array";
import { asMutation, flattenChanges } from "@utils/property-mutations";
import { newID } from "@utils/id";
import { toParentScope, toScope } from "@utils/scope";
import { useAsyncEffect } from "@utils/effects";
import { toFieldName, toRef } from "@utils/property-refs";
import { merge } from "@utils/object";
import { Fn } from "@utils/fn";

import { useMutate } from "@ui/mutate";
import { HStack, SpaceBetween } from "@ui/flex";
import { Divider } from "@ui/divider";
import { Button } from "@ui/button";
import { showSuccess } from "@ui/notifications";
import { usePageId } from "@ui/app-page";
import { RelationButton } from "@ui/relation-label";
import { Text } from "@ui/text";

import { FormFields } from "./fields";
import { useISODate } from "@utils/date-fp";
import { subWeeks } from "date-fns";

interface Props {
  form: Form;
  data: FormData;
  onChanged: Fn<FormData, void>;
  onSubmitted?: Fn<Maybe<Ref>, void>;
}

export const FormSubmit = ({ form, data, onChanged, onSubmitted }: Props) => {
  const me = useMe();
  const pageId = usePageId();
  const [saving, setSaving] = useState(false);
  const fields = useFormFields(form);
  const mutate = useMutate();
  const formTemplate = useLazyEntity(form?.useTemplate?.id);
  const formTemplateSchedule = useLazyEntity<"schedule">(
    justOne(safeAs<HasRefs>(formTemplate)?.refs.repeat)?.id
  );
  const [suggestedName, setSuggestedName] = useState("");
  const { run } = useAiUseCase(formSubmissionName);

  const getLabel = useMemo(() => {
    const getField = maybeLookup(fields, ([f, d]) => f?.field);
    return (field: string) => {
      const [prop, def] = getField(field) || [];
      if (!prop) {
        return field;
      }

      return prop?.label || toFieldName(def || prop);
    };
  }, [fields]);

  const itemSource = useMemo(
    () =>
      form?.entity && {
        type: form?.entity,
        scope: form?.inLocation || form?.source.scope,
      },
    [form?.entity, form?.inLocation, form?.source.scope]
  );
  const create = useCreateFromObject(
    itemSource?.type || "task",
    itemSource?.scope,
    pageId
  );
  const moveResourcesTo = useCallback(
    (location: string) => {
      const resourceFields = filter(
        fields,
        ([f, d]) =>
          d?.type === "relations" &&
          equalsAny("resource", ensureMany(d?.options?.references))
      );
      const resources = flatMap(
        resourceFields,
        ([f, d]) => (data[f.field] || []) as Ref[]
      );

      if (isEmpty(resources)) {
        return;
      }

      const transaction = newID();

      // Move resources to the new location
      mutate(
        map(resources, (r) => ({
          id: r.id,
          method: "update",
          transaction,
          changes: [asMutation({ field: "location", type: "text" }, location)],
          source: { type: "resource", scope: form?.source.scope || location },
        }))
      );
    },
    [fields, data, mutate, form?.source.scope]
  );

  const onCreated = useCallback(
    (ref: Maybe<Ref>) => {
      setSaving(false);
      showSuccess("Form submitted!");
      onSubmitted?.(ref);
      explode();
      if (form?.inLocation && ref?.id) {
        moveResourcesTo(toScope(form?.inLocation, ref.id));
      }
      onChanged({});
    },
    [moveResourcesTo, setSaving, onChanged]
  );
  const createFromTemplate = useCreateFromTemplate(itemSource, onCreated);

  const readyToSubmit = useMemo(
    () =>
      some(fields, (f) => !isReadonlyField(f)) &&
      !!create &&
      createFromTemplate.ready,
    [fields, create, createFromTemplate.ready]
  );

  const handleSubmit = useCallback(() => {
    if (saving || !fields || !form) {
      return;
    }

    setSaving(true);

    // Add createdBy as the person submitting the form
    const finalFormData = {
      ...data,
      createdBy: toRef(me),
    } as FormData;

    let defaults = {};

    let overrides = merge<NestableOverrides>(
      form?.overrides?.length
        ? {
            [form?.useTemplate?.id || form?.entity || "*"]: flattenChanges(
              form?.overrides || []
            ),
          }
        : {},
      makeNestedOverrides(form, finalFormData, fields, me),
      {
        [form?.useTemplate?.id || form?.entity || "*"]: {
          title: suggestedName || form.name,
          refs: { fromForm: [{ id: form.id }] },
        },
      }
    );

    // If the form is setting a due date on the work, and the template's schedule
    // has a precreate(weeks) rule, set the start dates to the due date - precreate weeks
    const precreate = formTemplateSchedule?.precreate;
    const workDate = safeAs<HasDates>(
      overrides?.[form?.useTemplate?.id || "*"]
    )?.end;
    if (precreate && workDate) {
      overrides = merge(overrides, {
        task: {
          start: useISODate(workDate, (date) => subWeeks(date, precreate)),
        },
      });
    }

    if (form?.useTemplate) {
      createFromTemplate.create(form.useTemplate, {
        defaults,
        overrides,
      });
    } else {
      const [newly] =
        create?.([
          flattenFor(
            merge(defaults, overrides),
            when(form.entity, (type) => ({
              source: { type, scope: form.source.scope },
            })) || {}
          ),
        ]) || [];
      onCreated(newly);
    }
  }, [
    data,
    createFromTemplate.ready,
    create,
    suggestedName,
    formTemplateSchedule?.precreate,
  ]);

  const slowFormData = useSlowMemo(() => data, [1500, 100000], [data]);

  useAsyncEffect(async () => {
    // Don't suggest a name if the form is not ready to submit
    if (
      !form ||
      keys(data).length < Math.max(1, (form.fields?.length || 0) / 3)
    ) {
      return;
    }

    const suggested = await run({
      form: form,
      data: {
        ...mapKeys(data, (_, k) => getLabel(k)),
        submittedBy: me.fullName,
      },
    });
    setSuggestedName(suggested);
  }, [slowFormData]);

  if (!form) {
    return <></>;
  }

  return (
    <>
      <FormFields
        form={form}
        data={data}
        onChange={(changes) => onChanged({ ...data, ...changes })}
      />

      <Divider />

      <SpaceBetween>
        {when(toParentScope(form.inLocation), (p) => (
          <HStack gap={2}>
            <Text subtle>Creates work in </Text>
            <RelationButton size="tiny" relation={{ id: p }} />
          </HStack>
        )) || <span />}

        <Button
          variant="primary"
          onClick={handleSubmit}
          loading={saving}
          disabled={!readyToSubmit}
        >
          Submit form
        </Button>
      </SpaceBetween>
    </>
  );
};
