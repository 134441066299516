import { forEach, reduce, set } from "lodash";

import { Entity, EntityType } from "@api";
import { EntityForType } from "@api/mappings";

import generic from "@state/generic/workflows";
import view from "@state/views/workflows";
import backlog from "@state/backlog/workflows";
import task from "@state/tasks/workflows";
import schedule from "@state/schedule/workflows";
import meeting from "@state/meetings/workflows";
import action from "@state/actions/workflows";
import agenda from "@state/agendas/workflows";
import project from "@state/projects/workflows";
import sprint from "@state/sprint/workflows";
import outcome from "@state/outcomes/workflows";
import team from "@state/teams/workflows";
import deal from "@state/deal/workflows";
import contact from "@state/contact/workflows";
import company from "@state/company/workflows";

import { when } from "@utils/maybe";
import { ensureMany, pushDirty } from "@utils/array";
import { switchEnum } from "@utils/logic";

import { WorkflowDefinition, WorkflowDefinitionConfig } from "./types";

type EntityConfigs = {
  [T in EntityType | "*"]?: WorkflowDefinitionConfig<
    T extends EntityType ? EntityForType<T> : Entity
  >;
};

// Add new workflow definitions here
let _all: EntityConfigs;

const pushDef = (
  configs: EntityConfigs,
  type: EntityType | "*",
  def: WorkflowDefinition
): void => {
  const field: keyof WorkflowDefinitionConfig = switchEnum(def.trigger, {
    ACTION: (): keyof WorkflowDefinitionConfig => "actions",
    SUGGEST: "suggestions",
    WILL_UPDATE: "triggers",
    DID_UPDATE: "triggers",
  });

  if (!configs[type]?.[field]) {
    if (!!configs[type]) {
      set(configs, [type, field], []);
    } else {
      set(configs, [type], { [field]: [] });
    }
  }

  pushDirty(configs[type]?.[field] as WorkflowDefinition<Entity>[], def);
};

const lazyAll = () => {
  if (!_all) {
    const indexed = reduce(
      [
        view,
        task,
        team,
        project,
        meeting,
        action,
        agenda,
        deal,
        backlog,
        schedule,
        sprint,
        outcome,
        contact,
        company,
        generic,
      ] as WorkflowDefinitionConfig<Entity>[],
      (acc, config) => {
        forEach(config.actions, (def) =>
          forEach(ensureMany(def.type), (type) => pushDef(acc, type, def))
        );
        forEach(config.suggestions, (def) =>
          forEach(ensureMany(def.type), (type) => pushDef(acc, type, def))
        );
        forEach(config.triggers, (def) =>
          forEach(ensureMany(def.type), (type) => pushDef(acc, type, def))
        );
        return acc;
      },
      {} as EntityConfigs
    );

    _all = indexed;
  }
  return _all;
};

export const getTriggers = <T extends EntityType>(type?: T) => [
  ...(lazyAll()["*"]?.triggers || []),
  ...(when(type, (t) => lazyAll()[t]?.triggers) || []),
];

export const getSuggestions = <T extends EntityType>(type?: T) => [
  ...(lazyAll()["*"]?.suggestions || []),
  ...(when(type, (t) => lazyAll()[t]?.suggestions) || []),
];

export const getActions = <T extends EntityType>(type?: T) => [
  ...(lazyAll()["*"]?.actions || []),
  ...(when(type, (t) => lazyAll()[t]?.actions) || []),
];
