import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  MouseSensor,
  useDraggable,
  useSensor,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  addMonths,
  addYears,
  differenceInCalendarDays,
  endOfDay,
  endOfYear,
  getDaysInMonth,
  getDaysInYear,
  startOfDay,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import { last, map, throttle, times } from "lodash";
import {
  Fragment,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { titleCase } from "title-case";

import {
  DatabaseID,
  Entity,
  HasDates,
  Period,
  PropertyRef,
  Ref,
  View,
} from "@api";

import { getStore, useEntitySource, useQueueUpdates } from "@state/generic";
import { addPeriod } from "@state/schedule";
import { useEntityLabels } from "@state/settings";
import { useStableViewKey } from "@state/store";
import { ID } from "@state/types";
import {
  useAddToView,
  useDefaultsForView,
  useLazyGetView,
  useLazyItemsForView,
  useReorderItemsInView,
  useUpdateView,
  ViewResults,
} from "@state/views";

import { next, omitEmpty, OneOrMany, reverse, whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { formatPeriod } from "@utils/date";
import { toDirtyDate, toISODate, useISODate } from "@utils/date-fp";
import { useShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { switchEnum } from "@utils/logic";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { now } from "@utils/now";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toKey } from "@utils/property-refs";
import {
  SelectionState,
  SetSelectionState,
  usePageSelection,
  useSelectable,
  useSelected,
} from "@utils/selectable";

import { AddEntityInput } from "@ui/add-entity-input";
import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { DropHighlight } from "@ui/drop-highlight";
import { DropTarget, OnReorder, useItemDragDrop } from "@ui/entity-drag-drop";
import { FillSpace, HStack, SpaceBetween } from "@ui/flex";
import {
  AngleDoubleLeft,
  AngleDoubleRight,
  AngleDownIcon,
  Icon,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { GroupHeading } from "@ui/nested-groups";
import { OnHover } from "@ui/on-hover";
import { RelationLabel } from "@ui/relation-label";
import { Select } from "@ui/select";
import { useSuggestedProps } from "@ui/suggested-props";
import { Text, TextSmall } from "@ui/text";
import { GroupedItems } from "@utils/grouping";
import { toArray } from "@utils/set";
import styles from "./timeline.module.css";

interface Props {
  id: ID;
}

const ROW_HEIGHT = 32;

export function TimelineLayout({ id }: Props) {
  const pageId = usePageId();
  const view = useLazyGetView(id);
  const scroller = useRef<HTMLDivElement>(null);
  const setView = useUpdateView(id, true);
  const { items } = useLazyItemsForView(id);
  const onAdded = useAddToView(id);
  const mutate = useQueueUpdates(pageId);
  const [start, setStart] = useState<Date>(() =>
    startOfYear(subMonths(startOfDay(now()), 6))
  );
  const [end, setEnd] = useState<Date>(() =>
    endOfYear(addMonths(endOfDay(now()), 6))
  );
  const [period, setPeriod] = useStickyState<Period>(
    Period.Month,
    "view.timeline-period"
  );
  const [sideBarOpen, setSideBarOpen] = useStickyState<boolean>(
    true,
    "view.timeline-sidebar"
  );

  const itemsSource = useEntitySource(view?.entity || "task", view?.source);
  const onReorder = useReorderItemsInView(view, pageId);
  const pushTo = usePushTo();
  const [selection, setSelection] = usePageSelection();
  const suggestedProps = useSuggestedProps();
  const showProps = useMemo(
    () => reverse(whenEmpty(view?.showProps, suggestedProps || [])),
    [view?.showProps, suggestedProps]
  );

  const handleJumpTo = useCallback(
    (date: Date) => {
      if (!scroller.current) {
        return;
      }

      scroller.current.scrollLeft =
        toColWidth(period) * timesInPeriod(start, date, period) -
        (scroller.current.clientWidth - 300) / 2;
    },
    [start, period, scroller]
  );

  const handleChanged = useCallback(
    (item: Entity, start: Date, end: Date) => {
      mutate(
        asUpdate(item, [
          asMutation(
            { field: "start", type: "date" },
            //TODO: Use def.options.mode
            toISODate(start, "point")
          ),
          //TODO: Use def.options.mode
          asMutation({ field: "end", type: "date" }, toISODate(end, "point")),
        ])
      );
    },
    [setView]
  );

  const handleTranslation = useCallback(
    (
      source: ID,
      translation: { start?: number; end?: number; period: Period }
    ) => {
      const ids =
        selection?.selected?.size > 1 && selection.selected.has(source)
          ? toArray(selection.selected)
          : [source];
      const toChange = maybeMap(ids, (id) => items.lookup[id]);

      const updates = map(toChange, (item) =>
        asUpdate(
          item,
          omitEmpty([
            when(translation.start, (s) =>
              asMutation(
                { field: "start", type: "date" },
                // TODO: change to be generic
                useISODate(
                  safeAs<HasDates>(item)?.start,
                  (d) =>
                    d && toISODate(addPeriod(d, translation.period, s), "point")
                )
              )
            ),

            when(translation.end, (s) =>
              asMutation(
                { field: "end", type: "date" },
                // TODO: change to be generic
                useISODate(
                  safeAs<HasDates>(item)?.end,
                  (d) =>
                    d && toISODate(addPeriod(d, translation.period, s), "point")
                )
              )
            ),
          ])
        )
      );

      mutate(updates);
    },
    [selection.selected, items.all]
  );

  useShortcut(
    { key: "KeyD", shift: true },
    () => {
      const nextPeriod = next(
        [Period.Week, Period.Month, Period.Quarter],
        period
      );

      setPeriod(nextPeriod);
    },
    [period]
  );

  const subProps = useMemo(
    (): SubProps => ({
      view: view as View,
      start,
      end,
      period,
      items,
      onOpen: pushTo,
      itemsSource: itemsSource,
      selection: selection,
      setSelection: setSelection,
      sideBarOpen,
      setSideBarOpen,
      showProps: showProps,
      onAdded: onAdded,
      defaults: {},
      onReorder,
      jumpTo: handleJumpTo,
      onPeriodChanged: setPeriod,
      onChanged: handleChanged,
      onTranslate: handleTranslation,
    }),
    [
      view,
      items,
      itemsSource,
      selection,
      showProps,
      onAdded,
      start,
      end,
      period,
      sideBarOpen,
      onReorder,
      handleJumpTo,
      handleChanged,
      handleTranslation,
    ]
  );

  useLayoutEffect(() => {
    handleJumpTo(now());
  }, [period]);

  useLayoutEffect(() => {
    // When near the start or end of the horizontal scroll, change the start/end dates to have more periods
    const handleScroll = () => {
      if (!scroller.current) return;
      const scrollLeft = scroller.current.scrollLeft;
      const scrollWidth = scroller.current.scrollWidth;
      const parentWidth = scroller.current.clientWidth;
      const tolerance = parentWidth / 4;

      if (scrollLeft < tolerance) {
        setStart((prev) => subYears(prev, 1));
        // Keep scroll position
        scroller.current.scrollLeft =
          scroller.current.scrollLeft +
          toColWidth(period) * intervalsInPeriod(period, Period.Year);
      } else if (scrollLeft > scrollWidth - parentWidth - tolerance) {
        setEnd((prev) => addYears(prev, 1));
      }
    };

    // Throttle the scroll event listener
    const throttled = throttle(handleScroll, 100);

    // Wait for everything to load
    setTimeout(
      () => scroller.current?.addEventListener("scroll", throttled),
      1000
    );

    return () => scroller.current?.removeEventListener("scroll", throttled);
  }, []);

  if (!view || !subProps.view) {
    return <></>;
  }

  return (
    <div
      ref={scroller}
      className={cx(styles.container, !sideBarOpen && styles.sideBarHidden)}
    >
      <SideBar {...subProps} />
      <div className={styles.main}>
        <Grids {...subProps} />
        <Timelines {...subProps} />
        <Markers {...subProps} />
      </div>
    </div>
  );
}

interface SubProps {
  view: View;
  items: ViewResults<Entity>;
  itemsSource: DatabaseID;
  period: Period;
  start: Date;
  end: Date;
  selection: SelectionState;
  setSelection: SetSelectionState;
  sideBarOpen: boolean;
  setSideBarOpen: Fn<boolean, void>;
  showProps: PropertyRef[];
  onOpen: (t: Ref) => void;
  onAdded: (ts: OneOrMany<Ref>) => void;
  onChanged: (item: Entity, start: Date, end: Date) => void;
  onReorder: OnReorder<Entity>;
  onPeriodChanged: Fn<Period, void>;
  jumpTo: Fn<Date, void>;
  onTranslate: (
    source: ID,
    options: {
      start?: number;
      end?: number;
      period: Period;
    }
  ) => void;
  defaults: Partial<Entity>;
}

const SideBar = (props: SubProps) => {
  const { view, items, sideBarOpen, setSideBarOpen } = props;
  const pageId = usePageId();
  const toLabel = useEntityLabels(view.source.scope, { plural: true });

  if (!sideBarOpen) {
    return (
      <div className={styles.sidebar}>
        <div className={styles.header}>
          <SpaceBetween>
            <Button
              subtle
              onClick={() => setSideBarOpen(true)}
              icon={AngleDoubleRight}
            />
          </SpaceBetween>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.header}>
        <SpaceBetween>
          <Text bold>{toLabel(view?.entity)}</Text>
          <Button
            subtle
            onClick={() => setSideBarOpen(false)}
            icon={AngleDoubleLeft}
          />
        </SpaceBetween>
      </div>

      <Menu>
        {/* No Groupings */}
        {!items.grouped?.groups && (
          <MenuItems {...props} items={items.sorted} />
        )}

        {/* Groupings */}
        {map(items.grouped?.groups, (g: GroupedItems) => (
          <Fragment key={toKey(g.value)}>
            <MenuItems {...props} items={g.items} group={g} />
          </Fragment>
        ))}
      </Menu>
    </div>
  );
};

const Timelines = ({
  view,
  items,
  itemsSource,
  defaults,
  onAdded,
  selection,
  setSelection,
  showProps,
  ...props
}: SubProps) => {
  const toStableKey = useStableViewKey(getStore(view?.entity || "task"));

  return (
    <div className={styles.timelines}>
      {!items.grouped?.groups && (
        <div
          className={cx(styles.timelineGroup, styles.onlyGroup)}
          style={{
            height: ROW_HEIGHT * (items.sorted?.length || 0),
            width: "100%",
          }}
        >
          {map(items.sorted, (t, i) => (
            <TimelineItem
              key={toStableKey(t.id)}
              item={t}
              index={i}
              selection={selection}
              {...props}
            />
          ))}
        </div>
      )}

      {map(items.grouped?.groups, (g: GroupedItems, gi) => (
        <div
          key={toKey(g.value)}
          className={styles.timelineGroup}
          style={{ height: ROW_HEIGHT * g.items.length, width: "100%" }}
        >
          {map(g.items, (t, i) => (
            <TimelineItem
              key={toStableKey(t.id)}
              item={t}
              index={i}
              selection={selection}
              {...props}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const Markers = ({ period, start }: SubProps) => {
  return (
    <div className={styles.markers}>
      <div
        className={styles.marker}
        style={{
          left: toColWidth(period) * timesInPeriod(start, now(), period),
        }}
      >
        <div className={styles.label}>Today</div>
      </div>
    </div>
  );
};

const Grids = ({ period, onPeriodChanged, start, end, jumpTo }: SubProps) => {
  const colWidth = useMemo(() => toColWidth(period), [period]);
  const columns = useMemo(() => {
    const count = Math.ceil(timesInPeriod(start, end, period));
    return times(count, (i) => addPeriod(start, period, i));
  }, [start, end, period]);

  const upperPeriod = useMemo(
    () =>
      switchEnum(period, {
        day: Period.Month,
        week: Period.Month,
        else: () => Period.Year,
      }),
    [period]
  );
  const upperCounts = useMemo(() => {
    const count = Math.ceil(timesInPeriod(start, end, upperPeriod));
    return times(count, (i) => addPeriod(start, upperPeriod, i));
  }, [start, end, upperPeriod]);

  return (
    <div className={styles.gridContainer}>
      <HStack className={styles.actions} gap={0}>
        <Select
          value={{ id: period }}
          options={[
            // { id: "day", name: "Day" }, // Has issues with start/end dates
            { id: "week", name: "Week" },
            { id: "month", name: "Month" },
            { id: "quarter", name: "Quarter" },
          ]}
          searchable={false}
          onChange={(v) => v && onPeriodChanged?.(v.id as Period)}
        >
          <Button size="tiny" subtle>
            <HStack gap={0}>
              <Text subtle>{titleCase(period)}</Text>
              <Icon icon={AngleDownIcon} />
            </HStack>
          </Button>
        </Select>

        <Button size="tiny" subtle onClick={() => jumpTo?.(now())}>
          Today
        </Button>
      </HStack>

      <div className={styles.headers}>
        <HStack gap={0}>
          {map(upperCounts, (v, i) => (
            <div
              key={i}
              className={cx(styles.header, styles.sticky)}
              style={{
                width:
                  colWidth *
                  (period === Period.Day
                    ? getDaysInMonth(v)
                    : period === Period.Week
                    ? 4.33333
                    : Math.round(intervalsInPeriod(period, upperPeriod))),
              }}
            >
              <Text subtle bold>
                {formatPeriodLong(v, upperPeriod)}
              </Text>
            </div>
          ))}
        </HStack>

        <HStack gap={0}>
          {map(columns, (v, i) => (
            <div key={i} className={styles.header} style={{ width: colWidth }}>
              <TextSmall subtle>{formatPeriod(v, period)}</TextSmall>
            </div>
          ))}
        </HStack>
      </div>

      <div className={styles.grids}>
        {map(columns, (v, i) => (
          <div
            key={i}
            className={styles.column}
            style={{ width: colWidth }}
          ></div>
        ))}
      </div>
    </div>
  );
};

const TimelineItem = ({
  item: item,
  index,
  onOpen,
  start,
  onTranslate,
  end,
  selection,
  period,
}: { item: Entity; index: number } & Pick<
  SubProps,
  "start" | "end" | "period" | "onTranslate" | "onOpen" | "selection"
>) => {
  const pageId = usePageId();
  const width = useMemo(() => toColWidth(period), [period]);
  const selectableProps = useSelectable(item.id);
  const [transform, setTransform] = useState<{
    left: number;
    width: number;
  } | null>();
  const selected = useSelected(item.id, selection);

  const handleDragMove = useCallback(
    (e: DragMoveEvent) =>
      switchEnum(toDragType(e), {
        start: () => setTransform({ left: e.delta.x, width: -e.delta.x }),
        end: () => setTransform({ left: 0, width: e.delta.x }),
        move: () => setTransform({ left: e.delta.x, width: 0 }),
      }),
    [setTransform]
  );

  const css = useMemo(() => {
    const itemStart = when(safeAs<HasDates>(item)?.start, toDirtyDate);
    const itemEnd = when(safeAs<HasDates>(item)?.end, toDirtyDate);

    // Don't render if the item is missing a date field
    if (!(itemStart || itemEnd)) {
      return undefined;
    }

    return {
      top: index * ROW_HEIGHT,
      left:
        width *
        timesInPeriod(
          startOfDay(start),
          startOfDay(itemStart || now()),
          period
        ),
      width:
        width *
          timesInPeriod(
            startOfDay(itemStart || now()),
            endOfDay(itemEnd || now()),
            period
          ) +
        (transform?.width || 0),
      margin: `1px -1px`,
      height: ROW_HEIGHT - 2,
      transform: transform ? `translateX(${transform.left}px)` : "",
    };
  }, [item, start, end, period, index, transform]);

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const itemStart = when(safeAs<HasDates>(item)?.start, toDirtyDate);
      const itemEnd = when(safeAs<HasDates>(item)?.end, toDirtyDate);

      // Don't render if the item is missing a date field
      if (!(itemStart || itemEnd)) {
        setTransform(undefined);
        return undefined;
      }

      const delta = e.delta.x;
      const deltaDays = (delta / width) * daysInPeriod(period);
      const translation = switchEnum(e.active.data.current?.type, {
        start: (): { period: Period; start?: number; end?: number } => ({
          period: Period.Day,
          start: deltaDays,
        }),
        end: () => ({
          period: Period.Day,
          end: deltaDays,
        }),
        move: () => ({
          period: Period.Day,
          start: deltaDays,
          end: deltaDays,
        }),
      });

      onTranslate?.(item.id, translation);

      setTransform(undefined);
    },
    [onTranslate, item, period, width]
  );

  if (!css) {
    return <></>;
  }

  return (
    <DragContext onDragMove={handleDragMove} onDragEnd={handleDragEnd}>
      <div
        className={cx(styles.item, selected && styles.selected)}
        style={css}
        {...selectableProps}
        onDoubleClick={() => onOpen(item)}
      >
        <SpaceBetween direction="horizontal">
          <Draggable id={`${item.id}-start`} type="start">
            <div className={cx(styles.resizer, styles.left)} />
          </Draggable>

          <FillSpace>
            <Draggable id={item.id} type="move">
              <RelationLabel subtle icon={false} relation={item} />
            </Draggable>
          </FillSpace>

          <Draggable id={`${item.id}-end`} type="end">
            <div className={cx(styles.resizer, styles.right)} />
          </Draggable>
        </SpaceBetween>
      </div>
    </DragContext>
  );
};

const toColWidth = (period: Period) =>
  switchEnum(period, {
    day: () => 20,
    else: () => 100,
  });

const daysInPeriod = (period: Period, date?: Date) =>
  switchEnum(period, {
    month: 30,
    week: 7,
    quarter: 90,
    year: when(date, getDaysInYear) || 365,
    else: () => 1,
  });

const timesInPeriod = (start: Date, end: Date, period: Period) => {
  const days = differenceInCalendarDays(end, start);
  return days / daysInPeriod(period);
};

export const intervalsInPeriod = (unit: Period, period: Period): number =>
  daysInPeriod(period) / daysInPeriod(unit);

const formatPeriodLong = (date: Date, period: Period) =>
  switchEnum(period, {
    month: () =>
      `${formatPeriod(date, period)}, ${formatPeriod(date, Period.Year)}`,
    else: () => formatPeriod(date, period),
  });

type DragContextProps = {
  children: ReactNode;
  type?: string;
  direction?: "horizontal" | "vertical" | "both";
  onDragStart?: (event: DragStartEvent) => void;
  onDragMove?: (event: DragMoveEvent) => void;
  onDragEnd?: (event: DragEndEvent) => void;
};

const DragContext = ({
  type,
  children,
  direction = "both",
  onDragStart,
  onDragMove,
  onDragEnd,
}: DragContextProps) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 3,
    },
  });

  const handleDragEnd = useCallback(
    (event: DragEndEvent) =>
      (!type || type === toDragType(event)) && onDragEnd?.(event),
    [type, onDragEnd]
  );
  const handleDragStart = useCallback(
    (event: DragStartEvent) =>
      (!type || type === toDragType(event)) && onDragStart?.(event),
    [type, onDragStart]
  );
  const handleDragMove = useCallback(
    (event: DragMoveEvent) =>
      (!type || type === toDragType(event)) && onDragMove?.(event),
    [type, onDragMove]
  );

  return (
    <DndContext
      sensors={[mouseSensor]}
      modifiers={
        direction === "horizontal"
          ? [restrictToHorizontalAxis]
          : direction === "vertical"
          ? [restrictToVerticalAxis]
          : []
      }
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
    >
      {children}
    </DndContext>
  );
};

const Draggable = ({
  id,
  type,
  children,
}: {
  id: ID;
  type: string;
  children: ReactNode;
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: id,
    data: { type: type },
  });

  return (
    <div
      className={styles.contents}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};

export const toDragType = (
  event: DragMoveEvent | DragStartEvent | DragEndEvent
) => event.active.data.current?.type;

type MenuItemsProps = { items: Maybe<Entity[]>; group?: GroupedItems } & Omit<
  SubProps,
  "items"
>;

const MenuItems = (props: MenuItemsProps) => {
  const { view, items, group, onAdded, itemsSource } = props;
  const defaults = useDefaultsForView(
    view.id,
    group?.value ? [group.value] : undefined
  );

  return (
    <OnHover.Trigger>
      <MenuGroup inset={false}>
        {group && <GroupHeading view={view} group={group} />}
        {map(items, (t) => (
          <TimelineMenuItem key={t.id} item={t} {...props} />
        ))}
      </MenuGroup>
      <OnHover.Target>
        <DropTarget position="after" item={last(items)} type={view.entity}>
          <AddEntityInput
            size="small"
            source={itemsSource}
            onAdded={onAdded}
            defaults={defaults}
          />
        </DropTarget>
      </OnHover.Target>
    </OnHover.Trigger>
  );
};

const TimelineMenuItem = ({
  item,
  selection,
  group,
  onReorder,
  onOpen,
  jumpTo,
}: { item: Entity } & Omit<MenuItemsProps, "items">) => {
  const ref = useRef<HTMLDivElement>(null);
  const selectableProps = useSelectable(item.id);
  const { opacity, dropping } = useItemDragDrop({
    item,
    selection,
    group,
    onReorder,
    ref,
  });

  return (
    <div
      {...selectableProps}
      onDoubleClick={() => onOpen(item)}
      onClick={() => {
        const start = when(safeAs<HasDates>(item)?.start, toDirtyDate);
        const end = when(safeAs<HasDates>(item)?.end, toDirtyDate);
        const middle =
          !!start && !!end
            ? new Date((start.getTime() + end.getTime()) / 2)
            : start || end;
        when(middle, (d) => jumpTo(d));
      }}
    >
      <MenuItem
        ref={ref}
        className={styles.menuItem}
        wrapLabel={false}
        selected={selection.selected?.has(item.id)}
      >
        {dropping && <DropHighlight offset={-6} />}
        <RelationLabel className={styles.clip} relation={item} />
      </MenuItem>
    </div>
  );
};
